import React from "react";
import Getreward from "../assets/images/getreward.gif";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router";

const RewardModal = ({ isOpen, setIsOpen, setCoin, coin }) => {
  const navigate = useNavigate();


  const handleClose = () => {
    setIsOpen(false);
    navigate("/home");
  }

  return (
    <>
      {isOpen && (
        <div className="absolute inset-0 z-[41] overflow-hidden top-0 rounded-[10px]">
          <div className="absolute inset-0 w-full h-full bg-primary backdrop-blur-[5px] overflow-hidden"></div>
          <div className="flex justify-center items-center min-h-screen overflow-hidden bg-primary">
            <div className="relative md:mx-auto bg-primary border-2 overflow-hidden border-white rounded-[10px] flex flex-col w-[320px] h-[500px] sm:w-[412px]">
              <div
                className="absolute pr-2 pt-2 top-0 right-0 pb-2 cursor-pointer h-8 flex w-full justify-end items-center text-white"
                onClick={() => handleClose()}
              >
                <MdClose size={22} />
              </div>
              <div className="flex flex-col w-full h-full max-h-[500px] justify-center items-center">
                <img
                  src={Getreward}
                  alt="getreward"
                  className="w-[300px] h-[300px]"
                />
                <p className="text-lg text-yellow-300">New Reward Available</p>
                <p className="text-[28px] text-white pt-2">
                  Get Instant 100 Coins!
                </p>
                <p className="text-[15px] text-gray-600 pt-2">
                  Watch a simple and get rewarded
                </p>
                <div className="pt-2 pb-4 w-40" >
                  <button className="bg-[#d8e91ee6] w-full text-black text-base rounded-full py-[6px]">
                    Claim
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {showInter && <InterstitialAd adUnitId="/22632424389/appmobiquiz_Interstitial" />} */}
    </>
  );
};

export default RewardModal;
