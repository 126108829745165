/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const QuizList = ({ score, setScore, base_url, coin, setCoin }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState([]);
  const categoryId = localStorage.getItem("categoryId");

  const handleSelect = (i) => {
    if (selected === i && selected === questionData[currentQuestion]?.answer)
      return "select";
    else if (
      selected === i &&
      selected !== questionData[currentQuestion]?.answer
    )
      return "wrong";
    else if (i === questionData[currentQuestion]?.answer) return "select";
  };

  const handleCheck = (i) => {
    if (currentQuestion === (questionData?.length - 1)) {
      setTimeout(() => {
        navigate("/home/result");
      }, 1000);
    }
      setSelected(i);
      if (i === questionData[currentQuestion]?.answer) {
        setScore(score + 1);
        setCoin(+coin + 50);
        
      } else {
        setCoin(+coin - 10);
        
      }

      setTimeout(() => {
        if (i !== "") {
          setCurrentQuestion(currentQuestion + 1);
          setSelected();
        }
      }, 1000);
  };

  const fetch = async () => {
    const res = await axios
      .get(base_url + `api/v1/question/subcategory/${categoryId}`)
      .then((res) => {
        setQuestionData(res?.data?.questions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="bg-primary h-full">
    <div className="text-white flex bg-primary overflow-x-hidden pt-[45px] h-screen">
      <div className="w-full max-w-[520px] flex flex-col gap-6 pb-3 px-5 items-center box-border h-full">
        <div className="pt-8 pb-20 flex flex-col w-full gap-6 h-screen">
          <div>
            <div className=" text-[#bac8ff] font-bold">
              Question {currentQuestion + 1}/
              {questionData?.length }
            </div>
            <div className="text-lg font-bold text-center min-h-[70px] max-h-[70px] flex justify-center items-center mt-4">
              {questionData[currentQuestion]?.question}
              {/* Mamta Banerjee is the chief minister of which State? */}
            </div>
            <div className="grid grid-cols-2 gap-3 min-w-full my-4">
              {questionData[currentQuestion]?.options.map((option, index) => (
                <div
                  key={index}
                  className={`flex flex-col justify-center items-center text-base py-2 px-2 min-h-[50px] max-h-[50px] bg-[#20213f] border-2 border-[#404380] rounded-full cursor-pointer ${
                    selected && handleSelect(option)
                  }`}
                >
                  <div
                    className="flex justify-center items-center gap-2 p-2 rounded-full text-sm text-center shrink-0 w-full"
                    onClick={() => handleCheck(option)}
                    disabled={selected}
                    key={index}
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center items-center gap-1 text-lg font-bold">
              Your Score :<span className="text-[#ffc454]"> {score}</span>
              </div>
          </div>
          </div>          
        </div>
      </div>      
    </div>
  );
};

export default QuizList;
