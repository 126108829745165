import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import Gif from "../../assets/images/gif.gif";
import Coin from "../../assets/images/coin.svg";
import { useNavigate } from "react-router";
import RewardModal from "../../modal/RewardModal";

const Header = ({ coin, setCoin }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    navigate("/home");
  };

  const handleReward = () => {
    setIsOpen(true)
  };

  useEffect(() => {
    if (isOpen === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <>
      <RewardModal isOpen={isOpen} setIsOpen={setIsOpen} setCoin={setCoin} coin={coin}/>
    <div className="bg-primary z-10  flex px-1 items-center pt-3 justify-between mb-5 fixed left-0 max-w-[520px] lg:min-w-[360px] w-full">
      <div className="flex items-center gap-2">
        <span className="px-3 cursor-pointer">
          <img
            src={Logo}
            alt="quiz"
            style={{ height: "30px" }}
            onClick={handleClick}
          />
        </span>
      </div>
        <div className="flex items-center text-[12px]">
          {window.location.pathname !== '/' &&
            <div className="flex text-center gap-1 items-center  rounded-full px-2  cursor-pointer">
              <div className="flex item-center mb-2">
                <img className="w-[25px] object-contain" src={Gif} alt="gift" />
              </div>
              <div className="flex items-center text-white" onClick={handleReward}>
                Daily Reward
              </div>
            </div>
          }
        <div className="flex gap-1 py-2 items-center bg-secondary px-4 mx-5 rounded-full">
          <img src={Coin} className="w-[15px] object-contain" alt="coin" />
          <div className="flex gap-1 text-xs text-white">
            <div className="font-bold text-[12px]">{coin}</div>
            <div className="text-[10px] text-text">COINS</div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
