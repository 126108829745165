import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Main/Home";
import CategoryList from "./pages/Category/CategoryList";
import QuizList from "./pages/Quiz/QuizList";
import WinDetails from "./pages/Win/WinDetails";
import QuizDetails from "./pages/QuizDetails/QuizDetails";
import { ToastContainer } from "react-toastify";
import PlayQuizDetails from "./modal/PlayQuizDetails";
import Main from "./pages/Layout/Main";

const App = () => {
  const [score, setScore] = useState(0);
  const [coin, setCoin] = useState(localStorage.getItem("coin") || 0);
  const [categoryInfo, setCategoryInfo] = useState({});
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  // const base_url = "http://192.168.1.40:8000/";
  const base_url = "https://api.appmobiquiz.com/";
  useEffect(() => {
    localStorage.setItem("coin", coin);
  }, [coin]);

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Home base_url={base_url} setCoin={setCoin} coin={coin} />}
          />
          <Route element={<Main coin={coin} setCoin={setCoin} />}>

            <Route
              path="/home"
              element={
                <CategoryList
                  setName={setName}
                  setImg={setImg}
                  base_url={base_url}
                  setCategoryInfo={setCategoryInfo}
                  categoryInfo={categoryInfo}
                />
              }
            />
            <Route
              path="/home/quiz-details"
              element={<QuizDetails name={name} img={img} base_url={base_url} />}
            />
            <Route
              path="/home/details"
              element={<PlayQuizDetails
                base_url={base_url} setCoin={setCoin} coin={coin} />}
            />
            <Route
              path="/home/quiz/:categoryId"
              element={
                <QuizList
                  score={score}
                  setScore={setScore}
                  base_url={base_url}
                  setCoin={setCoin}
                  coin={coin}
                  categoryInfo={categoryInfo}
                />
              }
            />
            <Route
              path="/home/result"
              element={
                <WinDetails
                  score={score}
                  setScore={setScore}
                  coin={coin}
                  setCoin={setCoin}
                />
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
