import React from "react";
import { useNavigate } from "react-router";
import CoinImg from '../assets/images/coin.svg'

const PlayQuizDetails = ({ img, name,base_url, coin }) => {
  const navigate = useNavigate();
  const categoryId = localStorage.getItem('categoryId')

  const handleChange = () => {
    navigate(`/home`);
  };

  return (
    <div className="text-white h-screen flex bg-primary overflow-x-hidden pt-[45px]">
      <div
        className={`w-full max-w-[520px] h-full
        flex flex-col gap-6 pb-3 px-5 items-center box-border`}
      >
        <div className="flex flex-col md:gap-6 gap-2 bg-primary border-2 border-border rounded-[30px] py-5">
          <div className="flex gap-2 items-center px-5 ">
            {/* <img
              src={base_url + img}
              className="w-[60px] h-[60px] object-cover sm:w-[58px] rounded-full"
              alt="img"
            /> */}
        
              <div className="sm:text-[11px] text-[#64d2ff] font-black text-[9px]">
                {name}
              </div>
              <div className="flex items-center justify-center text-center w-full gap-1 sm:text-[18px] font-black text-[14px]">
                You won
                <img src={CoinImg} className="w-[20px] object-contain" alt="img" />{" "}
               {coin}
              </div>
           
          </div>

          <div className="flex ms:flex-row flex-col items-center justify-around m-2">
        
            <div
              className="self-center border-text w-full ms:w-[185px] border-[1px] whitespace-nowrap text-text text-center bg-secondary rounded-full font-bold text-sm py-3 md:px-4 px-10 cursor-pointer"
              onClick={handleChange}
            >
              PLAY NOW
            </div>
          </div>

          <ul className="list-disc flex flex-col gap-3 px-9 text-sm text-text">
            <li>You've got 90 - 150 seconds to answer all questions</li>
            <li>Answer as many questions as you can</li>
            <li>
              For Every Correct answer you will get +50 points and will loose
              -25 points on every Incorrect answer
            </li>
            <li>
              You can take help by using the lifelines present in the contest.
            </li>
            <li>
              Lifelines can be used for free or by using a given amount of coins
              for each lifeline.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PlayQuizDetails;
