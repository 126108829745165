import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Main = ({coin,setCoin }) => {
  return (
    <>
      <Header coin={coin} setCoin={setCoin}/>
      <div className="w-full">
        <Outlet />
      </div>
    </>
  );
};

export default Main;
